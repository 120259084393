import { Meta, Title } from '@solidjs/meta';
import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { LinkButton, TextLink, Heading, Container, Page, Section } from '@troon/ui';
import { Icon } from '@troon/icons';
import { useUser } from '../../../providers/user';
import { FacilityCard } from '../../../components/facility/card';
import { ReservationCard } from '../../../components/reservation-card';
import { Grid, GridHalf } from '../../../components/layouts/grid';
import { SupportButton } from '../../../components/support';
import { gql } from '../../../graphql';
import { getConfigValue } from '../../../modules/config';
import { cachedQuery } from '../../../graphql/cached-get';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { TeeTimeSearch } from '../../../components/tee-time-search';
import { UpsellAccess } from '../../../components/upsells/access';
import { Hero } from '../../../components/hero/photo';
import { PopularDestination } from './_components/popular-destination';
import type { JSX } from 'solid-js';
import type { FacilityCardFragment, ReservationFragment } from '../../../graphql';

export function LoggedIn() {
	const user = useUser();
	const data = createAsync(() => getHomeData({ isLoggedIn: !!user(), zipcode: user()?.me.zipcode ?? '' }));

	return (
		<Suspense>
			<Title>Troon | Book tee times and earn rewards at Troon golf courses</Title>
			<Meta
				name="description"
				content="Troon Rewards is the best way to book tee times at Troon courses around the world. Find exclusive rates and tee times. Earn points towards complimentary rounds of golf. Sign up today!"
			/>
			<Meta name="og:image" content={`https://${getConfigValue('MAP_HOST')}/assets/images/home-image.jpg`} />
			<h1 class="sr-only">Troon - Reserve golf course tee times</h1>

			<Hero src={[...(data()?.zipcodeFacilities ?? []), ...(data()?.facilities ?? [])][0]?.metadata?.hero?.url}>
				<Heading as="p" size="h1" class="pt-24 lg:text-6xl">
					Welcome, {user()?.me.firstName}
				</Heading>
				<p class="pb-24 text-lg">Explore the world of Troon and find the perfect course for your next round.</p>
			</Hero>

			<Container>
				<Page>
					<div class="relative z-30 -mt-36 rounded bg-white p-4 shadow-lg">
						<TeeTimeSearch />
					</div>

					<Suspense>
						<Show
							when={
								data()?.nextUpcomingReservations?.reservations.length && data()?.nextUpcomingReservations?.reservations
							}
						>
							{(reservations) => (
								<Section>
									<div class="flex flex-row flex-wrap items-center justify-between">
										<Heading as="h2" size="h3">
											Upcoming reservations
										</Heading>
										<LinkButton href="/reservations">
											<span class="sr-only md:not-sr-only">
												View all <span class="sr-only">reservations</span>
											</span>
											<Icon name="chevron-right" />
										</LinkButton>
									</div>

									<ul class="grid grid-cols-1 gap-8">
										<For each={reservations() as Array<ReservationFragment>}>
											{(reservation) => (
												<li>
													<ReservationCard {...reservation} />
												</li>
											)}
										</For>
									</ul>
								</Section>
							)}
						</Show>
					</Suspense>

					<Suspense>
						<Section>
							<div class="flex flex-row flex-wrap items-center justify-between">
								<Heading as="h2" size="h3">
									<Show when={data()?.zipcodeFacilities?.length} fallback="Featured courses">
										Nearby courses
									</Show>
								</Heading>
								<LinkButton href="/courses">
									<span class="sr-only md:not-sr-only">
										View all <span class="sr-only">courses</span>
									</span>
									<Icon name="chevron-right" />
								</LinkButton>
							</div>

							<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
								<For each={[...(data()?.zipcodeFacilities ?? []), ...(data()?.facilities ?? [])].slice(0, 3)}>
									{(course) => (
										<li>
											<FacilityCard level="h3" facility={course as FacilityCardFragment} loading="lazy" />
										</li>
									)}
								</For>
							</ul>
						</Section>
					</Suspense>

					<Show when={!user()?.activeTroonCardSubscription}>
						<UpsellAccess />
					</Show>

					<Section>
						<Heading as="h2" size="h3">
							Popular Destinations
						</Heading>

						<ul class="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
							<For each={data()?.featuredDestinations}>
								{(dest) => (
									<li>
										<PopularDestination {...dest} />
									</li>
								)}
							</For>
						</ul>
					</Section>

					<Grid>
						<GridHalf class="flex flex-col gap-6">
							<Heading as="h2" size="h2">
								Frequently asked questions
							</Heading>
							<p>Got questions? We’ve got answers.</p>
							<div>
								<SupportButton class="shrink" appearance="secondary">
									Contact support
								</SupportButton>
							</div>
						</GridHalf>

						<GridHalf border>
							<FrequentlyAskedQuestions content={faqs} />
						</GridHalf>
					</Grid>
				</Page>
			</Container>
		</Suspense>
	);
}

const homeQuery = gql(`
query home($isLoggedIn: Boolean!, $zipcode: String!) {
	zipcodeFacilities(zipcode: $zipcode, limit: 3) @include(if: $isLoggedIn) {
		...FacilityCard
	}
	facilities(featured: true, limit: 3) {
		...FacilityCard
	}
	nextUpcomingReservations(limit: 1) @include(if: $isLoggedIn) {
		reservations {
			...Reservation
		}
		hasMoreReservations
	}
	featuredDestinations {
		... on DestinationRegion {
			...PopularRegion
		}
		... on DestinationPlace {
			...PopularPlace
		}
	}
}`);

const getHomeData = cachedQuery(homeQuery);

const faqs: Record<string, () => JSX.Element> = {
	'How do I earn points?': () => (
		<p>
			To receive Troon Rewards points, you must present your Troon Rewards number at the time of check-in at the golf
			shop front counter in order to receive points for your own greens fee and/or merchandise (pre-tax).
		</p>
	),
	'Can I receive points for food and beverage?': () => (
		<p>Troon Rewards points may not be earned for purchases of food or beverages.</p>
	),
	'Do I get points for paying for my guest’s round?': () => (
		<p>
			Troon Rewards is a program for individuals. As a member of Troon Rewards, you may earn points for your own green
			fees and/or merchandise (pre-tax) in the golf shop.{' '}
		</p>
	),
	'Do Troon Rewards points expire?': () => (
		<p>
			Troon Rewards points do not expire, provided that member has earned or redeemed Troon Rewards points in the last
			eighteen (18) months. If a Member’s account is inactive for eighteen (18) consecutive months that Member will
			forfeit all accumulated points outside of the 18-month window, based on the date of the last transaction. Once
			points are forfeited or expire, they cannot be reinstated.
		</p>
	),
	'How many points do I need for a complimentary round?': () => (
		<p>
			Upon earning points, you are able to redeem those points towards a complimentary round of golf based on the
			redemption table/schedule. You can login to your <TextLink href="/account">account</TextLink> to view your points,
			which are valid towards complimentary rounds of golf. Simply book your round according to the terms & conditions
			of the program (and let the golf shop associate know that you would like to redeem your Troon Rewards points
			during booking). The staff will redeem the applicable points when you check in for your round and they will be
			removed from your account.
		</p>
	),
	'How I do use Troon Rewards points and cash for rounds of golf?': () => (
		<p>
			Troon Rewards points can now be combined with cash to redeem rounds of golf. Please see the{' '}
			<TextLink href="https://troon.com/troon-golf-rewards-program/troon-rewards-redemption/">
				Troon Rewards Redemption page
			</TextLink>
			, which shows the number of points and cash required per Troon facility. Be sure to check the month of the year,
			as the requirements change throughout the year.
		</p>
	),
	'How do the status levels work?': () => (
		<>
			<p class="mb-4">
				Status levels are based on the number of Troon Rewards points accumulated within a calendar year. Once you have
				achieved a status level, you will receive the associated discount on your next purchase. You will retain your
				status level for the remainder of the calendar year in which it is achieved through the end of the next calendar
				year.
			</p>
			<ul class="mb-4 list-disc ps-6">
				<li>2,000 points/year = Silver status & 10% discount</li>
				<li>4,000 points/year = Gold status & 15% discount</li>
				<li>8,000 points/year = Platinum status & 20% discount</li>
			</ul>
			<p>
				Status level discounts can be applied to the Troon Rewards member’s personal green fees and/or merchandise only.
			</p>
		</>
	),
};
